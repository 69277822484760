import React, { useState, useContext } from 'react';
import { Button, notification, Spin, List, Checkbox, Popconfirm, Upload as AntUpload } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { UserContext } from 'components';
import { uploadVideos, uploadVideosFromDrive } from '../requests';

const Upload = ({ project }) => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [analyzeOptions, setAnalyzeOptions] = useState({});

  const userAllowed = [1, 2, 11, 6, 5].includes(user.id);

  const handleFileChange = ({ fileList }) => {
    setSelectedVideos(fileList);
    setAnalyzeOptions(fileList.reduce((acc, file) => {
      acc[file.uid] = false;
      return acc;
    }, {}));
  };

  const handleDelete = (uid) => {
    const updatedVideos = selectedVideos.filter(video => video.uid !== uid);
    setSelectedVideos(updatedVideos);
    const newAnalyzeOptions = { ...analyzeOptions };
    delete newAnalyzeOptions[uid];
    setAnalyzeOptions(newAnalyzeOptions);
  };

  const handleAnalyzeChange = (uid, checked) => {
    setAnalyzeOptions({
      ...analyzeOptions,
      [uid]: checked,
    });
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      const response = await uploadVideos({
        user: user,
        project,
        data: selectedVideos.map(video => ({
          ...video,
          analyze: analyzeOptions[video.uid],
        })),
      });
      if (response.status === 200) {
        notification.success({
          message: 'Uploaded videos',
          description: 'Videos are now being uploaded and shared to your project members',
        });
      } else if (response.status === 202) {
        notification.success({
          message: 'Video already exists',
          description: 'Looks like this video is already in your shoebox',
        });
      } else {
        notification.error({
          message: 'Upload failed',
          description: 'Failed to upload videos',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Upload failed',
        description: 'Failed to upload videos',
      });
    }
    setSelectedVideos([]);
    setAnalyzeOptions({});
    setLoading(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px',
        backgroundColor: '#f9f9f9',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        margin: '0 auto',
      }}
    >
      <h2
        style={{
          fontSize: '24px',
          fontWeight: '600',
          color: '#333',
          marginBottom: '24px',
        }}
      >
        Select Videos from Your Computer
      </h2>

      {userAllowed ? (
        <AntUpload
          multiple
          beforeUpload={() => false}
          onChange={handleFileChange}
          fileList={selectedVideos}
          showUploadList={false}
          accept="video/*"
        >
          <Button
            type="primary"
            style={{
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              borderRadius: '8px',
              padding: '6px 20px',
              fontSize: '16px',
              height: '40px',
              marginBottom: '24px',
            }}
          >
            Select Videos
          </Button>
        </AntUpload>
      ) : (
        <h2
          style={{
            fontSize: '20px',
            color: '#888',
            textAlign: 'center',
            marginBottom: '24px',
          }}
        >
          You don’t have permissions to upload to Shoebox
        </h2>
      )}

      {selectedVideos.length > 0 && (
        <List
          bordered
          dataSource={selectedVideos}
          renderItem={item => (
            <List.Item
              actions={[
                <Checkbox
                  onChange={(e) => handleAnalyzeChange(item.uid, e.target.checked)}
                  checked={analyzeOptions[item.uid]}
                  style={{ color: '#555' }}
                >
                  Analyze
                </Checkbox>,
                <Popconfirm
                  title="Are you sure to remove this video?"
                  onConfirm={() => handleDelete(item.uid)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    style={{ color: '#ff4d4f' }}
                  />
                </Popconfirm>,
              ]}
              style={{
                backgroundColor: '#fff',
                borderRadius: '8px',
                marginBottom: '8px',
                padding: '12px 16px',
              }}
            >
              <span style={{ color: '#333', fontSize: '16px' }}>{item.name}</span>
            </List.Item>
          )}
          style={{
            width: '100%',
            backgroundColor: '#fff',
            borderRadius: '8px',
            marginBottom: '24px',
            border: '1px solid #e8e8e8',
          }}
        />
      )}

      <Button
        type="primary"
        onClick={handleUpload}
        disabled={loading || selectedVideos.length === 0} // Disabled unless files are chosen
        loading={loading}
        style={{
          backgroundColor: selectedVideos.length === 0 ? '#d9d9d9' : '#1890ff',
          borderColor: selectedVideos.length === 0 ? '#d9d9d9' : '#1890ff',
          borderRadius: '8px',
          padding: '6px 20px',
          fontSize: '16px',
          height: '40px',
          width: '200px',
          transition: 'all 0.3s',
        }}
      >
        Upload Videos
      </Button>

      {loading && (
        <Spin
          style={{
            marginTop: '20px',
          }}
        />
      )}
    </div>
  );
};

export default Upload;  